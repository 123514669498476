.title {
    color: #e0e0e0;
    font-size: 34px;

    @media (max-width: 991px) {
        font-size: 22px;
        text-align: center;
        margin: 0 0 15px 0;
    }
}

.main__img {
    margin-bottom: 20px;

    img {
        max-width: 100%;
        height: auto;
        border-radius: 16px;
    }

    min-height: 400px;
    max-width: 270px;
    border-radius: 16px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top center !important;


    @media (max-width: 992px) {
        min-height: 260px;
        max-width: 185px;
        margin: 0 auto 15px auto;
    }
}

.content {
    font-family: "Inter", sans-serif;
    color: #e0e0e0;
    font-size: 16px;
    line-height: 1.5;
}

.list_prop {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
        position: relative;
        color: #e0e0e0;
        margin-right: 15px;
        padding-left: 20px;
        font-size: 16px;
    }

    li:first-child {
        padding-left: 0;
    }

    li::before {
        content: "";
        position: absolute;
        top: 12px;
        right: -20px;
        width: 5px;
        height: 5px;
        background: #2f80ed;
        border-radius: 16px;
    }

    li:last-child::before {
        display: none;
    }
}

.category {
    font-family: "Inter", sans-serif;
    display: inline-flex;
    flex-wrap: wrap;

    li {
        a {
            cursor: pointer;
            display: inline-flex;
            color: #e0e0e0;
            padding: 8px 16px 10px 16px;
            line-height: 1;
            border-radius: 16px;
            margin: 0 15px 15px 0;
            font-size: 12px;
            border: 2px solid #151f30;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            &:hover {
                border: 2px solid #2f80ed;
                background: #2f80ed;
            }
        }
    }
}

.download_box {
    margin-bottom: 20px;

    @media (max-width: 991px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
}

.download__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border: 2px solid #151f30;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
    padding: 15px 0;
    border-radius: 16px;

    @media (max-width: 991px) {
        padding: 10px 0;
        width: 100%;
    }
}

.attr__wrap {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.download__icon {

    @media (max-width: 991px) {
        display: none;
    }
}

.download__weight {
    font-size: 16px;
    color: #e0e0e0;

    @media (max-width: 576px) {
        font-size: 16px;
    }
}

.download__link a {
    font-size: 16px;
    color: #4CAF50;
    text-decoration: none;
    border-bottom: 1px dotted;
    text-transform: lowercase;

    &:hover {
        border-bottom: 1px dotted transparent;
    }

    img {
        position: absolute;
        top: 20px;
        right: 0;
    }
}

.download__quality {
    font-size: 16px;
    color: #e0e0e0;
    text-decoration: none;
    border-radius: 16px;
    text-transform: lowercase;
}

.download__ext {
    font-size: 16px;
    color: #e0e0e0;
    text-decoration: none;
    border-radius: 16px;
    text-transform: lowercase;
}

.backArrow {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: #151f30;
    border-radius: 30px;
    color: #e0e0e0;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: #2f80ed;
    }

    @media (max-width: 992px) {
        margin: 0 auto;
    }
}

.backArrow_text {
    padding-left: 5px;
}

.manual__link {
    font-size: 14px;
    color: #2f80ed;
    text-decoration: none;
    border-bottom: 1px dotted;

    &:hover {
        border-bottom: 1px dotted transparent;
    }
}

.torrent_not_added {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #eb575773;
    transition: all 0.3s ease-in-out;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 16px;

    span {
        color: #eb5757;
        text-align: center;
        font-size: 16px;
    }
}

.country__title {
    font-family: "Inter", sans-serif;
    color: #e0e0e0;
    font-size: 16px;
    line-height: 1.5;
}

.country__list {
    display: flex;
    flex-wrap: wrap;
}

.country__list_item {
    font-family: "Inter", sans-serif;
    display: inline-flex;
    color: #e0e0e0;
    font-size: 14px;
    margin: 0 12px 6px 0;
}

.actors__title {
    font-family: "Inter", sans-serif;
    color: #e0e0e0;
    font-size: 16px;
    line-height: 1.5;
}

.actors__list {
    display: flex;
    flex-wrap: wrap;
}

.actors__list_item {
    font-family: "Inter", sans-serif;
    display: inline-flex;
    color: #e0e0e0;
    font-size: 14px;
    margin: 0 12px 6px 0;
}