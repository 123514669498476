.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
:after,
:before {
  box-sizing: inherit;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1>* {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2>* {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3>* {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4>* {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5>* {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6>* {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1>* {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  top: 0;
}

.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 10px 0;
  background: #151f30;
  z-index: 9;
  border-bottom: 1px solid #151f30;
}

.header__logo_link {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #e0e0e0;
  text-decoration: none;
  text-transform: uppercase;
}

.header__logo_desc-min {
  position: absolute;
  color: #2f80ed;
  font-size: 8px;
}

.sub-navigation a {
  text-decoration: none;
  display: block;
  transition: 0.3s linear;
}

.sub-navigation ul {
  list-style: none;
  margin: 0;
}

.topmenu>li {
  display: inline-flex;
  position: relative;
}

.topmenu>li>a {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #e0e0e0;
  padding: 20px 20px 20px 0;
}

.submenu li a:hover,
.topmenu .active,
.topmenu>li>a:hover {
  color: #2f80ed;
}

.submenu {
  position: absolute;
  left: -1px;
  z-index: 5;
  width: 450px;
  column-count: 3;
  background: #151f30;
  border-radius: 16px;
  padding: 15px 10px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(58px);
  transition: 0.3s ease;
}

@media (max-width: 768px) {
  .submenu {
    transform: translateX(-550px) translateY(58px);
  }
}

@media (max-width: 768px) {
  .submenu.mobile-view {
    position: fixed;
    left: -1px;
    top: -58px;
    z-index: 5;
    width: 100%;
    column-count: 2;
    background: #151f30;
    padding: 15px 10px;
    transform: translateY(58px);
    transition: 0.3s ease;
    height: 100vh;
    opacity: 1;
    visibility: visible;
    border-radius: 0;
  }
}

.submenu li {
  position: relative;
}

.submenu a {
  font-family: Montserrat, sans-serif;
  color: #e0e0e0;
  font-size: 14px;
  padding: 10px 20px;
}

.submenu .submenu {
  position: absolute;
  top: 0;
  left: calc(100% - 1px);
  left: -webkit-calc(100% - 1px);
}

@media (min-width: 768px) {
  .sub-navigation li:hover>.submenu {
    visibility: visible;
    opacity: 1;
    transform: translateY(58px);
  }
}

.sign-in {
  font-family: Montserrat, sans-serif;
}

.sign-in__text a {
  font-size: 14px;
  color: #e0e0e0;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.3s ease;
}

.sign-in__text a i {
  color: #2f80ed;
  transition: all 0.3s ease;
}

.sign-in__text a:hover {
  color: #2f80ed;
}

.home-slider__card {
  display: flex;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}

.home-slider__card:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #131720, transparent);
  z-index: 1;
}

.home-slider__card:hover img.max-img {
  transform: scale(1.05);
  filter: blur(2px);
}

.home-slider__card:hover .home-slider__card_play {
  opacity: 1;
  visibility: visible;
}

.home-slider__card:hover .home-slider__card_play .fa-circle-play {
  transform: scale(1);
}

.home-slider__card:hover .home-slider__card_title a {
  color: #2f80ed;
}

.home-slider__card:hover .home-slider__card_rating {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.home-slider__card_title a {
  font-size: 26px;
  color: #e0e0e0;
  bottom: 60px;
  left: 15px;
  padding-right: 5%;
}

.home-slider__card_play,
.home-slider__card_title a {
  position: absolute;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

.home-slider__card_play {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.home-slider__card_play .fa-circle-play {
  font-size: 46px;
  color: #e0e0e0;
  transform: scale(0.7);
  font-weight: 100;
  transition: all 0.3s ease-in-out;
}

.home-slider__card_genre-list {
  position: absolute;
  font-size: 14px;
  color: #e0e0e0;
  bottom: 20px;
  left: 15px;
  transition: all 0.3s ease;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  z-index: 2;
}

.home-slider__card_genre-list li a {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #e0e0e0;
  padding: 10px 10px 10px 0;
  text-decoration: none;
}

.home-slider__card_rating {
  transform: scale(0.7);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  font-size: 14px;
  color: #2f80ed;
  top: 10px;
  right: 15px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  background: #131720;
  padding: 5px 10px;
  border-radius: 16px;
}

.home-slider__card_rating span {
  color: #e0e0e0;
}

.home-slider__card_rating .fa-star {
  font-weight: 100;
}

.home-slider img {
  transition: all 0.3s ease;
}

.home-filter__genre {
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  background: #131720;
  border-radius: 16px;
  padding: 10px;
  margin: 0;
}

@media (max-width: 768px) {
  .home-filter__genre {
    justify-content: flex-start;
  }
}


.home-filter__genre li a,
.home-filter__genre li span {
  font-family: "Inter", sans-serif;
  cursor: pointer;
  display: inline-flex;
  padding: 6px 16px;
  border-radius: 16px;
  margin: 0 5px 5px 0;
  color: #e0e0e0;
  text-decoration: none;
  font-size: 14px;
  border: 1px solid #151f30;
  line-height: 1;
  transition: all 0.3s ease-in-out;
}

.home-filter__genre li a:hover,
.home-filter__genre li span:hover {
  color: #2f80ed;
}

.home-filter__genre li a.active,
.home-filter__genre li span.active {
  font-family: "Inter", sans-serif;
  cursor: pointer;
  display: inline-flex;
  padding: 6px 16px;
  border-radius: 16px;
  color: #2f80ed;
  text-decoration: none;
  font-size: 14px;
  background: #151f30;
  border: 1px solid #151f30;
  line-height: 1;
}

.home-filter__wrap {
  padding: 30px;
}

@media (max-width: 768px) {
  .home-filter__wrap {
    padding: 15px;
  }
}

.home-filter__wrap,
.pagination__wrap {
  background-color: #151f30;
  border-radius: 16px;
}

.pagination__wrap {
  padding: 15px 25px;
}

@media (max-width: 768px) {
  .pagination__wrap {
    flex-direction: column;
  }
}

.pagination__total {
  display: flex;
}

@media (max-width: 768px) {
  .pagination__total {
    margin-bottom: 30px;
  }
}

.pagination__count,
.pagination__sum,
.pagination__text {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: #e0e0e0;
  text-decoration: none;
}

.pagination__wrap-list {
  display: flex;
}

@media (max-width: 768px) {
  .pagination__wrap-list {
    flex-wrap: wrap;
  }
}

.pagination__list {
  display: flex;
}

@media (max-width: 768px) {
  .pagination__list {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.pagination__list li a {
  font-family: Montserrat, sans-serif;
  display: inline-flex;
  border-radius: 16px;
  margin: 0 5px 0 0;
  color: #e0e0e0;
  text-decoration: none;
  font-size: 12px;
  background: #131720;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .pagination__list li a {
    margin: 5px;
  }
}

.pagination__list li a:hover {
  color: #2f80ed;
}

.pagination__list li a.active {
  font-family: Montserrat, sans-serif;
  display: inline-flex;
  border-radius: 16px;
  margin: 0 5px 0 0;
  color: #2f80ed;
  text-decoration: none;
  font-size: 12px;
  background: #131720;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .pagination__list li a.active {
    margin: 5px;
  }
}

.pagination__btn_prev {
  font-family: Montserrat, sans-serif;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  padding: 10px;
  border-radius: 16px;
  margin: 0 10px 0 0;
  color: #e0e0e0;
  text-decoration: none;
  font-size: 12px;
  background: #131720;
  width: 40px;
  height: 35px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .pagination__btn_prev {
    display: none;
  }
}

.pagination__btn_prev:before {
  content: "";
  position: absolute;
  top: 12px;
  left: 18px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #e0e0e0;
  border-left: 2px solid #e0e0e0;
  transform: rotate(315deg);
}

.pagination__btn_next {
  font-family: Montserrat, sans-serif;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  padding: 10px;
  border-radius: 16px;
  color: #e0e0e0;
  text-decoration: none;
  font-size: 12px;
  background: #131720;
  width: 40px;
  height: 35px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .pagination__btn_next {
    display: none;
  }
}

.pagination__btn_next:after {
  content: "";
  position: absolute;
  top: 12px;
  left: 14px;
  width: 10px;
  height: 10px;
  border-top: 2px solid #e0e0e0;
  border-right: 2px solid #e0e0e0;
  transform: rotate(45deg);
}

.detail .fa-star {
  font-weight: 100;
  color: #2f80ed;
}

.detail__list-prop {
  font-family: Montserrat, sans-serif;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.detail__list-prop li {
  position: relative;
  color: #e0e0e0;
  margin-right: 20px;
  padding-left: 20px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .detail__list-prop li {
    margin-bottom: 10px;
  }
}

.detail__list-prop li:before {
  content: "";
  position: absolute;
  top: 8px;
  right: -20px;
  width: 5px;
  height: 5px;
  background: #2f80ed;
  border-radius: 16px;
}

.detail__list-prop li:first-child {
  padding-left: 0;
}

.detail__list-prop li:last-child {
  margin: 0;
}

.detail__list-prop li:last-child:before {
  content: "";
  display: none;
}

.detail__category {
  display: inline-flex;
  font-family: Montserrat, sans-serif;
}

.detail__category li a {
  cursor: pointer;
  display: inline-flex;
  color: #e0e0e0;
  padding: 10px;
  border-radius: 16px;
  margin: 0 15px 0 0;
  font-size: 14px;
  background: #151f30;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.detail__category li a:hover {
  color: #2f80ed;
}

.detail__social {
  display: inline-flex;
  font-family: Montserrat, sans-serif;
}

.detail__social li a {
  cursor: pointer;
  display: inline-flex;
  color: #e0e0e0;
  padding: 10px;
  border-radius: 16px;
  margin: 0 15px 0 0;
  font-size: 25px;
  background: #151f30;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.detail__social li a:hover {
  color: #2f80ed;
}

.detail__content {
  font-family: Roboto, sans-serif;
  color: #e0e0e0;
  font-size: 18px;
  line-height: 1.3;
}

.footer {
  padding: 40px 0;
  margin-top: 100px;
  background: #151f30;
  border-bottom: 1px solid #151f30;
}

@media (max-width: 768px) {
  .footer {
    margin-top: 30px;
  }
}

.footer__logo_link {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #e0e0e0;
  text-decoration: none;
  text-transform: uppercase;
}

.footer__logo_desc-min {
  position: absolute;
  color: #2f80ed;
  font-size: 8px;
}

.footer__menu {
  font-family: "Inter", sans-serif;
}

.footer__menu ul {
  column-count: 4;
}

@media (max-width: 768px) {
  .footer__menu ul {
    column-count: 2;
  }
}

.footer__menu ul li a {
  display: inline-flex;
  margin: 5px 0;
  color: #e0e0e0;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.footer__menu ul li a.active,
.footer__menu ul li a:hover {
  color: #2f80ed;
}

._mb-0 {
  margin-bottom: 0;
}

._mb-5 {
  margin-bottom: 5px;
}

._mb-10 {
  margin-bottom: 10px;
}

._mb-20 {
  margin-bottom: 20px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mb-20 {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mb-20 {
    margin-bottom: 10px;
  }
}

._mb-30 {
  margin-bottom: 30px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mb-30 {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mb-30 {
    margin-bottom: 15px;
  }
}

._mb-40 {
  margin-bottom: 40px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mb-40 {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mb-40 {
    margin-bottom: 20px;
  }
}

._mb-50 {
  margin-bottom: 50px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mb-50 {
    margin-bottom: 25px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mb-50 {
    margin-bottom: 25px;
  }
}

._mb-60 {
  margin-bottom: 60px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mb-60 {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mb-60 {
    margin-bottom: 30px;
  }
}

._mb-70 {
  margin-bottom: 70px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mb-70 {
    margin-bottom: 35px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mb-70 {
    margin-bottom: 35px;
  }
}

._mb-80 {
  margin-bottom: 80px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mb-80 {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mb-80 {
    margin-bottom: 40px;
  }
}

._mb-90 {
  margin-bottom: 90px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mb-90 {
    margin-bottom: 45px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mb-90 {
    margin-bottom: 45px;
  }
}

._mb-100 {
  margin-bottom: 100px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mb-100 {
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mb-100 {
    margin-bottom: 50px;
  }
}

._mt-0 {
  margin-top: 0;
}

._mt-5 {
  margin-top: 5px;
}

._mt-10 {
  margin-top: 10px;
}

._mt-20 {
  margin-top: 20px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mt-20 {
    margin-top: 10px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mt-20 {
    margin-top: 10px;
  }
}

._mt-30 {
  margin-top: 30px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mt-30 {
    margin-top: 15px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mt-30 {
    margin-top: 15px;
  }
}

._mt-40 {
  margin-top: 40px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mt-40 {
    margin-top: 20px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mt-40 {
    margin-top: 20px;
  }
}

._mt-50 {
  margin-top: 50px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mt-50 {
    margin-top: 25px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mt-50 {
    margin-top: 25px;
  }
}

._mt-60 {
  margin-top: 60px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mt-60 {
    margin-top: 30px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mt-60 {
    margin-top: 30px;
  }
}

._mt-70 {
  margin-top: 70px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mt-70 {
    margin-top: 35px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mt-70 {
    margin-top: 35px;
  }
}

._mt-80 {
  margin-top: 80px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mt-80 {
    margin-top: 40px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mt-80 {
    margin-top: 40px;
  }
}

._mt-90 {
  margin-top: 90px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mt-90 {
    margin-top: 45px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mt-90 {
    margin-top: 45px;
  }
}

._mt-100 {
  margin-top: 100px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mt-100 {
    margin-top: 50px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mt-100 {
    margin-top: 50px;
  }
}

._pt-0 {
  padding-bottom: 0;
}

._pt-5 {
  padding-bottom: 5px;
}

._pb-10 {
  padding-bottom: 10px;
}

._pb-20 {
  padding-bottom: 20px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pb-20 {
    padding-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pb-20 {
    padding-bottom: 10px;
  }
}

._pb-30 {
  padding-bottom: 30px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pb-30 {
    padding-bottom: 15px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pb-30 {
    padding-bottom: 15px;
  }
}

._pb-40 {
  padding-bottom: 40px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pb-40 {
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pb-40 {
    padding-bottom: 20px;
  }
}

._pb-50 {
  padding-bottom: 50px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pb-50 {
    padding-bottom: 25px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pb-50 {
    padding-bottom: 25px;
  }
}

._pb-60 {
  padding-bottom: 60px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pb-60 {
    padding-bottom: 30px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pb-60 {
    padding-bottom: 30px;
  }
}

._pb-70 {
  padding-bottom: 70px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pb-70 {
    padding-bottom: 35px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pb-70 {
    padding-bottom: 35px;
  }
}

._pb-80 {
  padding-bottom: 80px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pb-80 {
    padding-bottom: 40px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pb-80 {
    padding-bottom: 40px;
  }
}

._pb-90 {
  padding-bottom: 90px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pb-90 {
    padding-bottom: 45px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pb-90 {
    padding-bottom: 45px;
  }
}

._pb-100 {
  padding-bottom: 100px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pb-100 {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pb-100 {
    padding-bottom: 50px;
  }
}

._pt-0 {
  padding-top: 0;
}

._pt-5 {
  padding-top: 5px;
}

._pt-10 {
  padding-top: 10px;
}

._pt-20 {
  padding-top: 20px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pt-20 {
    padding-top: 10px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pt-20 {
    padding-top: 10px;
  }
}

._pt-30 {
  padding-top: 30px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pt-30 {
    padding-top: 15px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pt-30 {
    padding-top: 15px;
  }
}

._pt-40 {
  padding-top: 40px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pt-40 {
    padding-top: 20px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pt-40 {
    padding-top: 20px;
  }
}

._pt-50 {
  padding-top: 50px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pt-50 {
    padding-top: 25px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pt-50 {
    padding-top: 25px;
  }
}

._pt-60 {
  padding-top: 60px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pt-60 {
    padding-top: 30px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pt-60 {
    padding-top: 30px;
  }
}

._pt-70 {
  padding-top: 70px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pt-70 {
    padding-top: 35px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pt-70 {
    padding-top: 35px;
  }
}

._pt-80 {
  padding-top: 80px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pt-80 {
    padding-top: 40px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pt-80 {
    padding-top: 40px;
  }
}

._pt-90 {
  padding-top: 90px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pt-90 {
    padding-top: 45px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pt-90 {
    padding-top: 45px;
  }
}

._pt-100 {
  padding-top: 100px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pt-100 {
    padding-top: 50px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pt-100 {
    padding-top: 50px;
  }
}

._pl-0 {
  padding-left: 0;
}

._pl-5 {
  padding-left: 5px;
}

._pl-10 {
  padding-left: 10px;
}

._pl-20 {
  padding-left: 20px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pl-20 {
    padding-left: 10px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pl-20 {
    padding-left: 10px;
  }
}

._pl-30 {
  padding-left: 30px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pl-30 {
    padding-left: 15px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pl-30 {
    padding-left: 15px;
  }
}

._pl-40 {
  padding-left: 40px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pl-40 {
    padding-left: 20px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pl-40 {
    padding-left: 20px;
  }
}

._pl-50 {
  padding-left: 50px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pl-50 {
    padding-left: 25px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pl-50 {
    padding-left: 25px;
  }
}

._pl-60 {
  padding-left: 60px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pl-60 {
    padding-left: 15px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pl-60 {
    padding-left: 15px;
  }
}

._pl-90 {
  padding-left: 90px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pl-90 {
    padding-left: 45px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pl-90 {
    padding-left: 45px;
  }
}

._pr-0 {
  padding-right: 0;
}

._pr-5 {
  padding-right: 5px;
}

._pr-10 {
  padding-right: 10px;
}

._pr-20 {
  padding-right: 20px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pr-20 {
    padding-right: 10px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pr-20 {
    padding-right: 10px;
  }
}

._pr-30 {
  padding-right: 30px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pr-30 {
    padding-right: 15px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pr-30 {
    padding-right: 15px;
  }
}

._pr-40 {
  padding-right: 40px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pr-40 {
    padding-right: 20px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pr-40 {
    padding-right: 20px;
  }
}

._pr-50 {
  padding-right: 50px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pr-50 {
    padding-right: 25px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pr-50 {
    padding-right: 25px;
  }
}

._pr-60 {
  padding-right: 60px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._pr-60 {
    padding-right: 15px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._pr-60 {
    padding-right: 15px;
  }
}

._ml-0 {
  margin-left: 0;
}

._ml-5 {
  margin-left: 5px;
}

._ml-10 {
  margin-left: 10px;
}

._ml-20 {
  margin-left: 20px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._ml-20 {
    margin-left: 10px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._ml-20 {
    margin-left: 10px;
  }
}

._ml-30 {
  margin-left: 30px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._ml-30 {
    margin-left: 15px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._ml-30 {
    margin-left: 15px;
  }
}

._ml-40 {
  margin-left: 40px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._ml-40 {
    margin-left: 20px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._ml-40 {
    margin-left: 20px;
  }
}

._ml-50 {
  margin-left: 50px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._ml-50 {
    margin-left: 25px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._ml-50 {
    margin-left: 25px;
  }
}

._ml-60 {
  margin-left: 60px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._ml-60 {
    margin-left: 30px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._ml-60 {
    margin-left: 30px;
  }
}

._ml-90 {
  margin-left: 90px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._ml-90 {
    margin-left: 45px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._ml-90 {
    margin-left: 45px;
  }
}

._mr-0 {
  margin-right: 0;
}

._mr-5 {
  margin-right: 5px;
}

._mr-10 {
  margin-right: 10px;
}

._mr-20 {
  margin-right: 20px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mr-20 {
    margin-right: 10px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mr-20 {
    margin-right: 10px;
  }
}

._mr-30 {
  margin-right: 30px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mr-30 {
    margin-right: 15px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mr-30 {
    margin-right: 15px;
  }
}

._mr-40 {
  margin-right: 40px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mr-40 {
    margin-right: 20px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mr-40 {
    margin-right: 20px;
  }
}

._mr-50 {
  margin-right: 50px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mr-50 {
    margin-right: 25px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mr-50 {
    margin-right: 25px;
  }
}

._mr-60 {
  margin-right: 60px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mr-60 {
    margin-right: 30px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mr-60 {
    margin-right: 30px;
  }
}

._mr-90 {
  margin-right: 90px;
}

@media (max-width: 991px) and (min-width: 769px) {
  ._mr-90 {
    margin-right: 45px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  ._mr-90 {
    margin-right: 45px;
  }
}

.df {
  display: flex;
}

.df-inline {
  display: inline-flex;
}

.fjc-s {
  display: flex;
  justify-content: flex-start;
}

.fjc-c {
  display: flex;
  justify-content: center;
}

.fjc-e {
  display: flex;
  justify-content: flex-end;
}

.fjc-sb {
  display: flex;
  justify-content: space-between;
}

.fjc-sa {
  display: flex;
  justify-content: space-around;
}

.fai-s {
  display: flex;
  align-items: flex-start;
}

.fai-c {
  display: flex;
  align-items: center;
}

.fai-e {
  display: flex;
  align-items: flex-end;
}

.ffd-column {
  display: flex;
  flex-direction: column;
}

.ffd-row {
  display: flex;
  flex-direction: row;
}

.ffd-column-rev {
  display: flex;
  flex-direction: column-reverse;
}

.fw-wrap {
  flex-wrap: wrap;
}

.flex__75 {
  flex: 75%;
}

@media (max-width: 767px) {
  .flex__75 {
    flex: 100%;
  }
}

.flex__55 {
  flex: 55%;
}

@media (max-width: 767px) {
  .flex__55 {
    flex: 100%;
  }
}

.flex__50 {
  flex: 50%;
}

@media (max-width: 767px) {
  .flex__50 {
    flex: 100%;
  }
}

.flex__45 {
  flex: 45%;
}

@media (max-width: 767px) {
  .flex__45 {
    flex: 100%;
  }
}

.flex__35 {
  flex: 35%;
}

@media (max-width: 767px) {
  .flex__35 {
    flex: 100%;
  }
}

.flex__33 {
  flex: 33%;
}

@media (max-width: 767px) {
  .flex__33 {
    flex: 100%;
  }
}

.flex__25 {
  flex: 25%;
}

@media (max-width: 767px) {
  .flex__25 {
    flex: 100%;
  }
}

.flex__20 {
  flex: 20%;
}

@media (max-width: 767px) {
  .flex__20 {
    flex: 100%;
  }
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-27 {
  font-size: 27px;
}

.fs-28 {
  font-size: 28px;
}

.fs-29 {
  font-size: 29px;
}

.fs-30 {
  font-size: 30px;
}

.fs-34 {
  font-size: 34px;
}

.fs-35 {
  font-size: 35px;
}

.fs-36 {
  font-size: 36px;
}

@media (max-width: 991px) and (min-width: 769px) {
  .fs-36 {
    font-size: 22.5px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  .fs-36 {
    font-size: 18.94737px;
  }
}

.fs-38 {
  font-size: 38px;
}

.fs-40 {
  font-size: 40px;
}

@media (max-width: 991px) and (min-width: 769px) {
  .fs-40 {
    font-size: 25px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  .fs-40 {
    font-size: 21.05263px;
  }
}

.fs-44 {
  font-size: 44px;
}

.fs-46 {
  font-size: 46px;
}

@media (max-width: 991px) and (min-width: 769px) {
  .fs-46 {
    font-size: 28.75px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  .fs-46 {
    font-size: 24.21053px;
  }
}

.fs-48 {
  font-size: 48px;
}

@media (max-width: 991px) and (min-width: 769px) {
  .fs-48 {
    font-size: 30px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  .fs-48 {
    font-size: 25.26316px;
  }
}

.fs-60 {
  font-size: 60px;
}

@media (max-width: 991px) and (min-width: 769px) {
  .fs-60 {
    font-size: 37.5px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  .fs-60 {
    font-size: 31.57895px;
  }
}

.fs-80 {
  font-size: 80px;
}

@media (max-width: 991px) and (min-width: 769px) {
  .fs-80 {
    font-size: 50px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  .fs-80 {
    font-size: 42.10526px;
  }
}

.fs-90 {
  font-size: 90px;
}

@media (max-width: 991px) and (min-width: 769px) {
  .fs-90 {
    font-size: 56.25px;
  }
}

@media (max-width: 768px) and (min-width: 300px) {
  .fs-90 {
    font-size: 47.36842px;
  }
}

.color__white {
  color: #e0e0e0;
}

.color__black {
  color: #292323;
}

.color__red {
  color: #e0101b;
}

.color__orange {
  color: #ff5e14;
}

.color__blue {
  color: #00235a;
}

.color__gray {
  color: #bcbcbc;
}

.color__blue-light {
  color: #134ca2;
}

.color__blue-dark {
  color: #00235a;
}

.color__gray-b {
  color: #333;
}

.bground__blue {
  background: rgba(13, 81, 183, 0.6901960784313725);
}

.bground__purple {
  background: #ba4adf;
}

.bground__green {
  background: #359912;
}

.bground__violet {
  background: rgba(80, 77, 247, 0.8);
}

.bground__yellowlight {
  background: #ffe300;
}

.bground__lightgreen-grad {
  background: linear-gradient(73.37deg, #a3d540 -13.61%, #56a43b 126.78%);
}

.bground__greenblue {
  background: linear-gradient(90deg, #00b09b, #96c93d);
}

.bground__white {
  background: #fff;
}

.bground__gray {
  background: #f7f7f7;
}

.bground__red {
  background: #e0101b;
}

.bground__red_pale {
  background: #ea6e74;
}

.form-header-search {
  position: relative;
}

.form-header-search__input-text {
  border-radius: 16px;
  background-color: #131720;
  padding: 12px 15px;
  font-size: 14px;
  color: #e0e0e0;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.form-header-search__input-text:focus {
  border: 1px solid #2f80ed;
}

.form-header-search__btn_search-trans {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  height: 42px;
  width: 40px;
  right: 0;
  font-size: 16px;
}

.form-header-search__btn_search-trans i {
  color: #2f80ed;
  transition: all 0.3s ease;
}

.form-header-search__btn_search-trans:hover i {
  color: #e0e0e0;
}

.catalog__grid .card {
  display: flex;
  background: #151f30;
  border-radius: 16px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .catalog__grid .card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }
}

.catalog__grid .card:hover .card__img img {
  transform: scale(1.05);
  filter: blur(2px);
}

.catalog__grid .card:hover .card__play {
  opacity: 1;
  visibility: visible;
  background: linear-gradient(0deg, rgba(19, 23, 32, 0.4), transparent);
}

.catalog__grid .card:hover .card__play i {
  transform: scale(1);
}

.catalog__grid .card:hover .card__title a {
  color: #2f80ed;
}

.catalog__grid .card:hover .card__rating {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.catalog__grid .card__link {
  display: flex;
  overflow: hidden;
  border-radius: 16px 0 0 16px;
  line-height: 0;
  position: relative;
}

@media (max-width: 768px) {
  .catalog__grid .card__link {
    border-radius: 16px;
    margin-bottom: 15px;
  }
}

.catalog__grid .card__play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(0deg, rgba(19, 23, 32, 0.4), transparent);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.catalog__grid .card__play i {
  font-size: 46px;
  color: #e0e0e0;
  transform: scale(0.7);
  font-weight: 100;
  transition: all 0.3s ease-in-out;
}

.catalog__grid .card__img {
  line-height: 0;
}

.catalog__grid .card__img img {
  border-radius: 16px 0 0 16px;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .catalog__grid .card__img img {
    border-radius: 16px;
  }
}

.catalog__grid .card__rating {
  transform: scale(0.7);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  font-size: 14px;
  color: #2f80ed;
  top: 10px;
  right: 15px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  background: #151f30;
  padding: 5px 10px;
  border-radius: 16px;
}

.catalog__grid .card__rating span {
  color: #e0e0e0;
}

.catalog__grid .card__rating .fa-star {
  font-weight: 100;
}

.catalog__grid .card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 5px 15px 25px;
}

@media (max-width: 768px) {
  .catalog__grid .card__content {
    padding: 15px;
  }
}

.catalog__grid .card__title a {
  font-size: 20px;
  color: #e0e0e0;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.catalog__grid .card__title a:hover {
  color: #2f80ed;
}

.catalog__grid .card__year {
  display: flex;
  transition: all 0.3s ease-in-out;
}

.catalog__grid .card__director,
.catalog__grid .card__year {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: #e0e0e0;
  text-decoration: none;
}

.catalog__grid .card__genre-list {
  display: inline-flex;
  flex-wrap: wrap;
}

.catalog__grid .card__genre-list li a {
  font-family: Montserrat, sans-serif;
  display: inline-flex;
  font-weight: 400;
  font-size: 10px;
  color: #e0e0e0;
  padding: 5px 5px 5px 0;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.catalog__grid .card__genre-list li a:hover {
  color: #2f80ed;
}

.catalog__grid .card__slogan {
  font-family: Montserrat, sans-serif;
  display: inline-flex;
  font-weight: 400;
  font-size: 14px;
  color: #e0e0e0;
  padding: 5px 5px 5px 0;
  text-decoration: none;
  font-style: italic;
}

.catalog__grid-min .card {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .catalog__grid-min .card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }
}

.catalog__grid-min .card:hover .card__img img {
  transform: scale(1.05);
  filter: blur(2px);
}

.catalog__grid-min .card:hover .card__play {
  opacity: 1;
  visibility: visible;
  background: linear-gradient(0deg, rgba(19, 23, 32, 0.4), transparent);
}

.catalog__grid-min .card:hover .card__play i {
  transform: scale(1);
}

.catalog__grid-min .card:hover .card__title a {
  color: #2f80ed;
}

.catalog__grid-min .card:hover .card__rating {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.catalog__grid-min .card:hover .card__title {
  color: #2f80ed;
}

.catalog__grid-min .card__link {
  position: relative;
  display: flex;
  border-radius: 16px;
  line-height: 0;
}

@media (max-width: 768px) {
  .catalog__grid-min .card__link {
    border-radius: 16px;
    margin-bottom: 15px;
  }
}

.catalog__grid-min .card__play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(0deg, rgba(19, 23, 32, 0.4), transparent);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.catalog__grid-min .card__play i {
  font-size: 46px;
  color: #e0e0e0;
  transform: scale(0.7);
  font-weight: 100;
  transition: all 0.3s ease-in-out;
}

.catalog__grid-min .card__img {
  overflow: hidden;
  border-radius: 16px;
  line-height: 0;
}

.catalog__grid-min .card__img img {
  border-radius: 16px;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .catalog__grid-min .card__img img {
    border-radius: 16px;
  }
}

.catalog__grid-min .card__year {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: #e0e0e0;
  text-decoration: none;
  display: flex;
  transition: all 0.3s ease-in-out;
}

.catalog__grid-min .card__rating {
  transform: scale(0.7);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  font-size: 14px;
  color: #2f80ed;
  top: 10px;
  right: 20px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  background: #151f30;
  padding: 5px 10px;
  border-radius: 16px;
}

.catalog__grid-min .card__rating span {
  color: #e0e0e0;
}

.catalog__grid-min .card__rating .fa-star {
  font-weight: 100;
}

.catalog__grid-min .card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .catalog__grid-min .card__content {
    padding: 15px;
  }
}

.catalog__grid-min .card__title {
  transition: all 0.3s ease-in-out;
  color: #e0e0e0;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.catalog__grid-min .card__title a {
  font-size: 16px;
  color: #e0e0e0;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.catalog__grid-min .card__title a:hover {
  color: #2f80ed;
}

.catalog__grid-min .card__director {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: #e0e0e0;
  text-decoration: none;
}

.catalog__grid-min .card__genre-list {
  display: inline-flex;
  flex-wrap: wrap;
}

.catalog__grid-min .card__genre-list li a {
  font-family: Montserrat, sans-serif;
  display: inline-flex;
  font-weight: 400;
  font-size: 10px;
  color: #e0e0e0;
  padding: 5px 5px 5px 0;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.catalog__grid-min .card__genre-list li a:hover {
  color: #2f80ed;
}

.catalog__grid-min .card__slogan {
  font-family: Montserrat, sans-serif;
  display: inline-flex;
  font-weight: 400;
  font-size: 14px;
  color: #e0e0e0;
  padding: 5px 5px 5px 0;
  text-decoration: none;
  font-style: italic;
}

.social__list {
  display: flex;
}

.social__list li a {
  display: inline-flex;
  text-decoration: none;
  margin: 0 15px 0 0;
}

.btn,
.social__list li a {
  transition: all 0.3s ease-in-out;
}

.btn {
  font-family: Montserrat, sans-serif;
  cursor: pointer;
}

.btn-download {
  background: #151f30;
  border-radius: 16px;
}

.btn-download span {
  display: inline-flex;
  color: #e0e0e0;
  padding: 20px 40px;
  font-size: 16px;
}

.btn-download:hover {
  background: #2f80ed;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  background-color: #131720;
  -webkit-font-smoothing: antialiased;
  background-color: #131720;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
ul {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
}

.w-100 {
  width: 100%;
}

.hr-gray {
  height: 1px;
  background: #eee;
}

.f-montserrat {
  font-family: Montserrat, sans-serif;
}

.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.fontw-700 {
  font-weight: 700;
}

.fontw-600 {
  font-weight: 600;
}

.fontw-500 {
  font-weight: 500;
}

.fontw-400 {
  font-weight: 400;
}

.tt_upper {
  text-transform: uppercase;
}

.b-radius_16 {
  border-radius: 16px;
}

.border-blue-1 {
  border-top: 1px solid rgba(47, 128, 237, 0.1);
}

.img-res {
  width: 100%;
  height: auto;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}