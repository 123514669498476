
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 10px 0;
  background: #151f30;
  z-index: 9;
  border-bottom: 1px solid #151f30;

  @media (max-width: 991px) {
    padding: 10px 0;
    background: #131720;
  }
}

.wrap_logo {
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.logo {
  @media (max-width: 991px) {
    position: relative;
    z-index: 2;
    width: 100%;
  }
}

.topmenu {
  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;

    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    li {
      a {
        display: inline-flex;
        font-family: "Inter", sans-serif;
        font-weight: 100;
        font-size: 14px;
        color: #e0e0e0;
        padding-right: 15px;
        text-decoration: none;
        transition: all .3s ease-in-out;

        &:hover {
          color: #2f80ed;
        }

        @media (max-width: 991px) {
          margin-bottom: 20px;
          padding: 0;
          font-size: 14px;
        }
      }
    }
  }
}

.topmenu_mobile {
  @media (max-width: 991px) {
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    background: #131720;
    width: 100%;
    top: 55px;
    left: 0;
    height: 100vh;
    padding: 80px 0 0 0;
    transition: all 0.3s ease;
    transform: translateX(-100%);
  }
}

.topmenu_mobile__view {
  transform: translateX(0%);
}

.sign_in {
  font-family: "Inter", sans-serif;

  @media (max-width: 991px) {
    padding-top: 20px;
  }
}

.hamburger {
  display: none !important;
  @media (max-width: 991px) {
    display: inline-flex !important;
    flex-direction: column;
    // position: fixed;
    width: 30px;
    z-index: 3;
    top: 18px;
    right: 30px;
  }

  @media (max-width: 576px) {
    display: inline-flex !important;
    flex-direction: column;
    // position: fixed;
    width: 30px;
    z-index: 3;
    top: 18px;
    right: 15px;
  }
}

.close_wrap {
  display: none;
  @media (max-width: 991px) {
    display: inline-flex;
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 999;
    border-radius: 1em;
    border: 1px solid #c0494b;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    color: #c0494b;
    transform: translateY(-50px);
    transition: all 0.3s ease-in-out;
  }
}

.close_wrap__view {
  transform: translateY(0);
}

.close__icon {
}

