.wrap {
  font-family: "Inter", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  background: #151f30;
  z-index: 99;
  overflow-y: scroll;
  max-height: calc(100vh - 190px);
  max-width: 100%;
  top: calc(100% + 11px);

  @media (max-width: 991px) {
    z-index: 10;
    top: 68px;
    width: 100%;
    max-height: 335px;
    min-height: 335px;
    background: #131720;
    padding: 0;
  }
}

.wrap::-webkit-scrollbar-thumb {
  background-color: #2e7de9;
  border-radius: 20px;
  border: 4px solid #151f30;

  @media (max-width: 991px) {
    background-color: #2e7de9;
    border-radius: 20px;
    border: 4px solid #131720;
  }
}

.wrap::-webkit-scrollbar {
  width: 12px;
}

.search {
  position: relative;

  @media (min-width: 991px) {
    min-width: 360px;
  }

  @media (max-width: 991px) {
    position: fixed;
    left: 15px;
    right: 15px;
    top: 5px;
  }
}

.search_input__text {
  font-family: "Inter", sans-serif;
  border-radius: 14px;
  background-color: #131720;
  padding: 12px 45px 12px 15px;
  font-size: 14px;
  color: #e0e0e0;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  border: 2px solid transparent;
  outline: none;

  &:focus {
    border: 2px solid rgba(49, 130, 237, 0.4509803922);
  }

  &::placeholder {
    color: #e0e0e0;
  }

  @media (max-width: 1200px) {
    background-color: #131720;
  }

  @media (max-width: 991px) {
    font-size: 14px;
    background-color: #151f30;
    padding: 10px 45px 10px 15px;
  }

  @media (max-width: 576px) {
    font-size: 14px;
    background-color: #151f30;
    padding: 10px 45px 10px 15px;
  }
}

.btn_search {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0px;
  top: 13%;
  font-size: 16px;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}

.btn__search {
  &_view {
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 0px;
    top: 13%;
    font-size: 16px;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
  }
}

.close {
  width: 22px;
}

.wrap_view {
  display: flex;
  flex-direction: column;
}

.wrap_hidden {
  @media (max-width: 992px) {
    display: none;
  }
}

.box {
  display: flex;
}

.list {
  display: flex;
}

.item {
  // width: 100%;

  @media (max-width: 992px) {
    width: 150px;
    margin-bottom: 30px;
  }
}

.wrap__btn {
  position: relative;
  display: flex;
}

.search__arrow {
  display: inline-flex;
}

.search__arrow_left {
  cursor: pointer;
  display: inline-flex;
  width: 10%;
  height: 400px;
  background: linear-gradient(90deg, #151f30, transparent);
  position: fixed;
  top: 83px;
  left: 0;
  z-index: 9;

  @media (max-width: 992px) {
    display: none;
  }
}

.search__arrow_right {
  cursor: pointer;
  display: inline-flex;
  width: 10%;
  height: 400px;
  background: linear-gradient(-90deg, #151f30, transparent);
  position: fixed;
  top: 83px;
  right: 0;
  z-index: 9;

  @media (max-width: 992px) {
    display: none;
  }
}

.loader {
  &__search {
    position: absolute;
    left: -40px;
    top: 3px;
    margin: 0 !important;
    width: 8% !important;

    @media (max-width: 992px) {
      left: 0;
      right: 0;
      top: 2px;
      margin: 0 auto !important;
      width: 4% !important;
    }

    @media (max-width: 768px) {
      left: 0;
      right: 0;
      top: 1px;
      margin: 0 auto !important;
      width: 6% !important;
    }

    @media (max-width: 576px) {
      left: 0;
      right: 0;
      top: 0;
      margin: 0 auto !important;
      width: 8% !important;
    }
  }
}