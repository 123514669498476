
.box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 40px 15px 40px 15px;
    border: 2px solid #a24044;
    border-radius: 16px;
    margin: 0 15px;

    @media (max-width:768px) {
        position: inherit;
        padding: 40px 15px 40px 15px;
        border: 2px solid #a24044;
        border-radius: 16px;
        margin: 0 15px;
    }
}

.title {
    color: #a24044;
    font-size: 28px;
    font-weight: 500;
}  


.desc {
    color: #a24044;
    font-size: 14px;
    font-weight: 100;
}  
