.wrap {
    display: flex;
    justify-content: space-between;
    background: #151f30;
    margin-bottom: 30px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;

    &:hover {

        img {
            transform: scale(1.05);
            filter: blur(2px);
        }

        .title {
            color: #2f80ed;
        }
    }

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg,#131720,transparent);
        z-index: 1;
        border-radius: 16px;
    }
}

img {
    transition: all .3s ease;
}

.title {
    font-family: 'Inter', sans-serif;
    text-decoration: none;
    font-size: 22px;
    color: #e0e0e0;
    transition: all 0.3s ease-in-out;
    position: absolute;
    bottom: 30px;
    left: 20px;
    z-index: 1;

    &:hover {
        color: #2f80ed;
    }

    @media (max-width: 768px) {
        font-size: 16px;
        left: 15px;
        bottom: 15px;
    }

    @media (max-width: 320px) {
        font-size: 14px;
    }
}

.count {
    background: #151f3080;
    padding: 5px 16px;
    border-radius: 16px;
    color: #e0e0e0;
    position: absolute;
    bottom: 30px;
    right: 20px;
    z-index: 1;

    @media (max-width: 768px) {
        font-size: 10px;
        right: 10%;
        bottom: 65%;
    }
}




