.wrap {
    padding: 60px 0;
    margin-top: 60px;
    border-bottom: 1px solid #151f30;
    // background: #151f30 url("../../img/webp/cinema-bg-footer.webp");
    background-position: 100% 15%;
    background-size: cover;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #151f3080;
    }

    @media (max-width: 768px) {
        margin-top: 30px;
        padding: 30px 0;
    }
}

.menu {

    ul {
        column-count: 2;

        li {

            a {
                display: inline-flex;
                margin: 5px 0;
                color: #e0e0e0;
                text-decoration: none;
                font-size: 14px;
                transition: all 0.3s ease-in-out;

                @media (max-width: 768px) {
                    font-size: 14px;
                }

                &:hover {
                    color: #2f80ed;
                }

                &:active {
                    color: #2f80ed;
                }
            }

            a.active {
                color: #2f80ed;
            }
        }
    }
}
