.wrap {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
}

.box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 0;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }

  a {
    color: #e0e0e0;
    text-decoration: none;
  }
}

.item {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: #e0e0e0;
  transition: 0.5s;
  position: relative;
  margin-right: 10px;

  i {
    display: flex;
    align-items: center;
  }

  a {
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #2f80ed;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.item_active {
  font-size: 14px;
  line-height: 22px;
  color: #e0e0e0;
  transition: 0.5s;
  position: relative;
  margin-right: 0;
}
