.card {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;

  &:hover {
    .play {
      opacity: 1;
      visibility: visible;
      background: linear-gradient(0deg, rgba(19, 23, 32, 0.4), transparent);

      img {
        transform: scale(1);
      }
    }

    @media (max-width: 992px) {
      .play {
        opacity: 1;
        visibility: visible;
        background: transparent;

        img {
          transform: scale(1);
        }
      }
    }

    .rating {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }

    .title {
      color: #2f80ed;
      a {
        color: #2f80ed;
      }
    }
  }
}

.link {
  display: flex;
  overflow: hidden;
  border-radius: 16px;
  line-height: 0;
  position: relative;
  width: 100%;
  height: 260px;

  @media (max-width: 414px) {
    height: 225px;
  }
}

.img {
  width: 100%;
  background-position: center center !important;
  background-size: cover !important;
}

.play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(0deg, rgba(19, 23, 32, 0.4), transparent);
  z-index: 1;
  opacity: 0;
  visibility: hidden;

  @media (max-width: 992px) {
    opacity: 1;
    visibility: visible;
    background: transparent;
  }

  img {
    transform: scale(0.7);

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.rating {
  // transform: scale(0.7);
  // opacity: 0;
  // visibility: hidden;
  // color: #e0e0e0;
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  color: #e0e0e0;
  position: absolute;
  font-size: 14px;
  top: 10px;
  right: 20px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  background: #151f30;
  padding: 5px 10px;
  border-radius: 16px;

  @media (max-width: 768px) {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    color: #e0e0e0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  transition: all 0.3s ease-in-out;
  color: #e0e0e0;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;

  @media (max-width: 768px) {
    height: 20px;
    overflow: hidden;
  }

  a {
    font-size: 14px;
    color: #e0e0e0;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }
}

.year {
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  color: #e0e0e0;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.category_list {
  display: inline-flex;
  flex-wrap: wrap;

  li {
    a {
      font-family: 'Inter', sans-serif;
      display: inline-flex;
      font-weight: 400;
      font-size: 12px;
      color: #e0e0e0;
      margin: 0 10px 5px 0;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
    }
  }
}
