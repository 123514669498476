@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Rubik:ital,wght@0,300;0,400;0,500;1,400&display=swap");

::selection {
  background: #2f80ed;
}

body {
  margin: 0;
}

.d-block {
  display: block;
}

.purge-link {
  text-decoration: none;
}

.link {
  text-decoration: none;
}

.link.link_white {
  color: #e0e0e0;
}

.link.link_download {
  border-bottom: 1px solid #e0e0e0;
}

.link.link_download:hover {
  border-bottom: 1px solid transparent;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  margin-top: 85px;
}

body::-webkit-scrollbar-thumb {
  background-color: #2f80ed;
  border-radius: 20px;
  border: 4px solid #151f30;
}

body::-webkit-scrollbar-track {
  background: #151f30;
}

body::-webkit-scrollbar {
  width: 12px;
}

@media (max-width: 768px) {
  #root {
    margin-top: 60px;
  }
}

.icon-min__25 {
  width: 25px;
  height: 25px;
}
.icon-min__25__15 {
  width: 25px;
  height: 15px;
}

.width-50 {
  width: 50px;
}

header ul li a.active, footer ul li a.active {
  color: #2f80ed;
}

/* home-slider */
.home-slider .Card-box {
  width: 90%;
  margin: 0 auto;
}

.home-slider .Card__link {
  height: 360px;
}

@media (max-width: 768px) {
  .home-slider .Card__link {
    height: 260px;
  }
}

.catalog__grid-min .Card-box {
  width: 90%;
  margin: 0 auto;
}
.catalog__grid-min .Card__link {
  height: 260px;
}



.home-slider .home-slider__card_play {
  top: 0;
}
.home-slider .slick-slide {
  /* margin: 0 15px 0 0 !important; */
}
.home-slider .slick-slider {
  /* height: 400px !important; */
}
.home-slider .slick-dots li button:before {
  opacity: 1;
  color: #e0e0e0;
}
.home-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #2767bf;
}
/* home-slider end */

/* home-filter */

.home-filter {

}

@media (max-width: 768px) {
  .home-filter {
    margin-top: 60px;
  }
}

/* home-filter end */

/* catalog__grid-min */
.catalog__grid-min .slick-dots {
  bottom: -35px;
}

.catalog__grid-min .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #2767bf;
}
.catalog__grid-min .slick-dots li button:before {
  opacity: 1;
  color: #e0e0e0;
}
/* catalog__grid-min end */


.home-slider__card_title a {
  bottom: 60px;
}

.home-slider__card_genre-list li a {
  font-family: "Inter", sans-serif;
}

.home-slider .slick-dots {
  bottom: -35px;
}

.hamburger {
  padding: 0 !important;
}

.hamburger-box {
  width: 30px !important;
  height: 20px !important;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  width: 30px !important;
  height: 1px !important;
  background-color: #2f80ed !important;
}

.is-active .hamburger-inner,
.is-active .hamburger-inner:after,
.is-active .hamburger-inner:before {
  width: 30px !important;
  height: 1px !important;
  background-color: #eb5757 !important;
}

.home-slider .slick-arrow.slick-prev::before,
.home-slider .slick-arrow.slick-next::before {
  content: "";
}

.home-slider .slick-prev:hover, .home-slider .slick-prev:focus, .home-slider .slick-next:hover, .home-slider .slick-next:focus {
  background: #131720cc !important;
}

.home-slider .slick-prev {
  top: 49%;
  left: -15px;
  background: #131720f2;
  z-index: 8;
  height: 100%;
  width: 8%;
  transition: background .3s ease-in-out;
}
.home-slider .slick-prev::after {
  content: '';
  position: absolute;
  top: 33%;
  left: 50%;
  border-top: 2px solid #e0e0e0;
  border-left: 2px solid #e0e0e0;
  width: 50px;
  height: 50px;
  transform: rotate(-45deg);
}
.home-slider .slick-prev:hover {
  background: #131720cc !important;
}


.home-slider .slick-next {
  top: 49%;
  right: -15px;
  background: #131720f2;
  z-index: 8;
  height: 100%;
  width: 8%;
  transition: background .3s ease-in-out;
}
.home-slider .slick-next:hover {
  background: #131720cc !important;
}

.home-slider .slick-next:hover::after {

}
.home-slider .slick-next::after {
  content: '';
  position: absolute;
  top: 33%;
  right: 50%;
  border-top: 2px solid #e0e0e0;
  border-right: 2px solid #e0e0e0;
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
}

@media (max-width: 768px) {
  .home-slider .slick-next {
    width: 4%;
  }  
}

/* catalog__grid-min */
.catalog__grid-min .slick-prev {
  left: -65px;
  padding: 13px 10px 13px 16px;
  z-index: 1;
}

.catalog__grid-min .slick-next {
  right: -65px;
  padding: 13px 16px 13px 10px;
  z-index: 1;
}

.catalog__grid-min .slick-next,
.catalog__grid-min .slick-prev {
  top: 40%;
  background: #151f30;
  border-radius: 30px;
  width: 55px;
  height: 55px;
  transition: all 0.3s ease-in-out;
}

.catalog__grid-min .slick-next:hover,
.catalog__grid-min .slick-prev:hover {
  background: #2f80ed;
}

.catalog__grid-min .slick-arrow.slick-prev::before {
  content: "";
  width: 15px;
  height: 15px;
  border-top: 2px solid #e0e0e0;
  border-left: 2px solid #e0e0e0;
  display: inline-flex;
  transform: rotate(315deg);
  opacity: 1;
}

.catalog__grid-min .slick-arrow.slick-next::before {
  content: "";
  width: 15px;
  height: 15px;
  border-top: 2px solid #e0e0e0;
  border-right: 2px solid #e0e0e0;
  display: inline-flex;
  transform: rotate(45deg);
  opacity: 1;
}

/* catalog__grid-min end */

/* Paginate */

.selected a[aria-current="page"] {
  color: #2f80ed;
}

.manual-desktop__link {
  display: inline-flex;
  font-family: "Inter", sans-serif;
  font-weight: 100;
  font-size: 24px;
  color: #2f80ed;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px dashed #2f80ed;
  margin: 0 0 0 10px;
}

.manual-desktop__link:hover {
  border-bottom: 1px dashed transparent; 
}

@media (max-width: 768px) {
  .row-column-reverse-mob {
    flex-direction: column-reverse;
  }
}


/* .pagi__list {
  display: flex;
  flex-wrap: wrap;
}

.pagi__list_li {
  list-style: none;
  margin: 0 10px;
}

.pagi__list_item {
  cursor: pointer;
  color: #e0e0e0;
  padding: 8px 16px;
  background: #151f30;
  border-radius: 1em;
} */

/* Paginate end */


/* success-window */

/* #success-window {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #131720d9;
  z-index: 9999;
} */

/* success-window end */