.wrap {
    background: #151f30;
    border-radius: 16px;
    padding: 30px;
    
    @media (max-width: 768px){
        margin-top: 15px ;
    }
}

.wrap__box {

}

.title {
    color: #e0e0e0;
    font-size: 26px;
    margin-bottom: 30px;
}
