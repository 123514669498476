.form {
  background-color: #131720;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 30px 20px;
  position: relative;
  width: 100%;
  border: 1px solid #151f30;
}

.form__contacts {
  padding: 30px 30px;
  margin-top: 30px;
}

.group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.input {
  font-family: "Inter", sans-serif;
  background-color: #151f30;
  border: 2px solid transparent;
  border-radius: 16px;
  height: 44px;
  position: relative;
  color: #fff;
  font-size: 14px;
  width: 100%;
  padding: 0 20px;

  &:focus {
    background-color: #151f30;
    border: 2px solid rgba(49, 130, 237, 0.4509803922);
  }
}

.input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #e0e0e0;
}

.textarea {
  font-family: "Inter", sans-serif;
  background-color: #151f30;
  border: 2px solid transparent;
  border-radius: 16px;
  height: 144px;
  position: relative;
  color: #e0e0e0;
  font-size: 14px;
  width: 100%;
  padding: 15px 20px;
  resize: none;

  &:focus {
    background-color: #151f30;
    border: 2px solid rgba(49, 130, 237, 0.4509803922);
  }

  &::placeholder {
    color: #e0e0e0;
  }
}

.btnSubmit {
  font-family: "Inter", sans-serif;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #151f30;
  border-radius: 16px;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  color: #e0e0e0;
  padding: 14px 50px;
  font-size: 16px;
  border: 0;

  &:hover {
    background: #2f80ed;
  }
}

.error {
  color: #b74649;
  font-size: 12px;
  padding: 5px 0 0 20px;
}

.link {
  color: #e0e0e0;
  font-size: 22px;
  text-decoration: none;
  transition: color .3s ease-in-out;

  &:hover {
    color: #2f80ed;
  }
}