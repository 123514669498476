.wrap {
    display: block;
}

.title {
    color: #e0e0e0;
    font-size: 20px;
    margin-bottom: 15px;
}

label {
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    font-size: 14px;
    color: #e0e0e0;
}

.custom_checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom_checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    position: relative;
    line-height: 1;
}

.custom_checkbox+label::before {
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 8px;
    margin-right: 0.8em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    background-color: #131720;
}

.custom_checkbox:checked+label::before {
    background-color: #131720;
}


.custom_checkbox:checked+label::after {
    content: "";
    position: absolute;
    left: 9px;
    top: 4px;
    width: 5px;
    height: 11px;
    background: transparent;
    border-bottom: 1px solid #2f80ed;
    border-right: 1px solid #2f80ed;
    transform: rotate(45deg);
}