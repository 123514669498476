.title {
  color: #e0e0e0;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.title_search {
  color: #e0e0e0;
  font-size: 20px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.title_found {
  color: #e0e0e0;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.form {
  position: relative;
  margin: 30px 0;
}

.search_input {
  font-family: "Inter", sans-serif;
  border-radius: 16px;
  background-color: #151f30;
  padding: 12px 45px 12px 15px;
  font-size: 14px;
  color: #e0e0e0;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  border: 2px solid transparent;
  outline: none;

  &:focus {
    border: 2px solid rgba(49, 130, 237, 0.4509803922);
  }

  &::placeholder {
    color: #e0e0e0;
  }

  @media (max-width: 1200px) {
    background-color: #131720;
  }

  @media (max-width: 992px) {
    font-size: 14px;
    background-color: #151f30;
  }

  @media (max-width: 576px) {
    font-size: 14px;
    background-color: #151f30;
  }
}

.btn__search_view {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0px;
  top: 0;
  font-size: 16px;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}