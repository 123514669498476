
.link {
    display: inline-flex;
    font-family: "Inter", sans-serif;
    font-weight: 100;
    font-size: 24px;
    color: #2f80ed;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px dashed #2f80ed;
    margin: 0 0 0 10px;

    &:hover {
        border-bottom: 1px dashed transparent; 
    }
}

.backArrow {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: #151f30;
    border-radius: 30px;
    color: #e0e0e0;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: #2f80ed;
    }
}