.card {
  display: flex;
  background: #151f30;
  border-radius: 16px;
  overflow: hidden;
  height: 260px;
  transition: all 0.3s ease-in-out;

  &:hover {
    .play {
      opacity: 1;
      visibility: visible;
      background: linear-gradient(0deg, rgba(19, 23, 32, 0.4), transparent);

      img {
        transform: scale(1);
      }
    }

    @media (max-width: 992px) {
      .play {
        opacity: 1;
        visibility: visible;
        background: transparent;

        img {
          transform: scale(1);
        }
      }
    }

    .rating {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
        color: #e0e0e0;
    }

    .title {
      a {
        color: #2f80ed;
      }
    }
  }
}

.play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(0deg, rgba(19, 23, 32, 0.4), transparent);
  z-index: 1;
  opacity: 0;
  visibility: hidden;

  @media (max-width: 992px) {
    opacity: 1;
    visibility: visible;
    background: transparent;
  }

  img {
    transform: scale(0.7);

    @media (max-width: 992px) {
      display: none;
    }
  }
}

.title {
  a {
    display: inline-flex;
    font-size: 14px;
    color: #e0e0e0;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.link {
  display: flex;
  overflow: hidden;
  border-radius: 16px 0 0 16px;
  line-height: 0;
  position: relative;
  width: 80%;
}

.img {
  width: 100%;
  background-position: center center !important;
  background-size: cover !important;
}

.category_list {
  display: inline-flex;
  flex-wrap: wrap;

  li {
    line-height: 0.5;

    a {
      font-family: 'Inter', sans-serif;
      display: inline-flex;
      font-weight: 400;
      font-size: 12px;
      color: #e0e0e0;
      padding: 5px 0 5px 0;
      margin: 0 10px 5px 0;
      text-decoration: none;
      border-bottom: 1px dotted #e0e0e0;
      transition: all 0.3s ease-in-out;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 15px 15px 0 15px;

  @media (max-width: 768px) {
    padding: 15px 5px 0 15px;
  }
}

.year {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #e0e0e0;
  text-decoration: none;
  display: flex;
  transition: all 0.3s ease-in-out;
}

.rating {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  color: #e0e0e0;
  position: absolute;
  font-size: 14px;
  top: 10px;
  right: 15px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  background: #151f30;
  padding: 5px 10px;
  border-radius: 16px;

  @media (max-width: 768px) {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    color: #e0e0e0;
  }
}

.props {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #e0e0e0;
  text-decoration: none;
}
