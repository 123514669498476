.wrap {
  background-color: #151f30;
  border-radius: 16px;
  padding: 15px 25px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px 60px;
  }
}

.total {
  display: flex;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
}

.count {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #e0e0e0;
  text-decoration: none;
}

.text {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #e0e0e0;
  text-decoration: none;
}

.sum {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #e0e0e0;
  text-decoration: none;
}

.wrap_list {
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.list {
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  li {
    a {
      cursor: pointer;
      font-family: "Montserrat", sans-serif;
      display: inline-flex;
      border-radius: 16px;
      margin: 0 5px 0 0;
      color: #e0e0e0;
      text-decoration: none;
      font-size: 14px;
      background: #131720;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;

      @media (max-width: 768px) {
        margin: 5px;
      }

      &:hover {
        color: #2f80ed;
      }
    }
    a.active {
      font-family: "Montserrat", sans-serif;
      display: inline-flex;
      border-radius: 16px;
      margin: 0 5px 0 0;
      color: #2f80ed;
      text-decoration: none;
      font-size: 12px;
      background: #131720;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        margin: 5px;
      }
    }

    &:first-child {
      @media (max-width: 768px) {
        position: absolute;
        left: 25px;
      }
    }

    &:last-child {
      @media (max-width: 768px) {
        position: absolute;
        right: 25px;
      }
    }
  }
}

.btn_prev {
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  padding: 10px;
  border-radius: 16px;
  margin: 0;
  color: #e0e0e0;
  text-decoration: none;
  font-size: 12px;
  background: #131720;
  width: 40px;
  height: 35px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    // display: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 14px;
    left: 14px;
    width: 7px;
    height: 7px;
    border-top: 2px solid #e0e0e0;
    border-left: 2px solid #e0e0e0;
    transform: rotate(315deg);
    transition: all 0.3s ease-in-out;
  }

  &:hover::before {
    border-top: 2px solid #2f80ed;
    border-left: 2px solid #2f80ed;
  }
}

.btn_next {
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  padding: 10px;
  border-radius: 16px;
  color: #e0e0e0;
  text-decoration: none;
  font-size: 12px;
  background: #131720;
  width: 40px;
  height: 35px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    // display: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 14px;
    left: 14px;
    width: 7px;
    height: 7px;
    border-top: 2px solid #e0e0e0;
    border-right: 2px solid #e0e0e0;
    transform: rotate(45deg);
    transition: all 0.3s ease-in-out;
  }

  &:hover::after {
    border-top: 2px solid #2f80ed;
    border-right: 2px solid #2f80ed;
  }
}
