.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #131720d9;
    z-index: 9999;
}

.modal_content {
    position: relative;
    max-width: 380px;
    margin: 0 auto;

    @media (max-width: 991px) {
        padding: 0;
        margin-top: 0;
        width: 90%;
    }
}

.modal_wrap {
    display: flex;
    flex-direction: column;
    background: #151f30;
    padding: 60px 0;
    border-radius: 16px;
    margin-top: 30vh;

    @media (max-width: 991px) {
        padding: 30px;
        margin-top: 10vh;
    }
}

.close {
    pointer-events: none;
    color: #e0e0e0;
    float: right;
    font-size: 28px;
    font-weight: 700;
    position: absolute;
    right: -15px;
    top: -30px;
    z-index: 999;

    @media (max-width: 991px) {
        top: -38px;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
    }
}

.box_icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon_border {
    width: 60px;
    height: 60px;
    display: inline-flex;
    border-radius: 5em;
    border: 2px solid #29b474;
}

.icon_checkbox {
    width: 25px;
    height: 15px;
    border-bottom: 2px solid #29b474;
    border-left: 2px solid #29b474;
    transform: rotate(315deg) translate(1px, 23px);
}

.text {
    color: #e0e0e0;
    display: flex;
    justify-content: center;
    margin: 30px;
    font-size: 20px;
    text-align: center;

    @media (max-width: 991px) {
        margin: 30px 0 0 0;
        font-size: 16px;
    }
}
